export default {
	images : [
		"my-wedding/gallery/3.jpg",
		"my-wedding/gallery/4.jpg",
		"my-wedding/gallery/5.jpg",
		"my-wedding/gallery/6.jpg",
		"my-wedding/gallery/7.jpg",
		"my-wedding/gallery/8.jpg",
		"my-wedding/gallery/9.jpg",
		"my-wedding/gallery/10.jpg",
		"my-wedding/gallery/11.jpg",
	]

}